const routePaths = {
    home: {
        index: "/"
    },
    tasks:{
        index: "/tasksPlan",
        children:{
            companies:{
                index: "/tasksPlan/companies",
                edit: (id) => `/tasksPlan/companies/${id ? id : ":id"}/edit`,
            },
            controllers:{
                index: "/tasksPlan/controllers",
                edit: (id) => `/tasksPlan/controllers/${id ? id : ":id"}/edit`,
            },
            sealers:{
                index: "/tasksPlan/sealers",
                edit: (id) => `/tasksPlan/sealers/${id ? id : ":id"}/edit`,
            },
            statistic:{
                index: "/tasksPlan/statistic",
                edit: (id) => `/tasksPlan/statistic/${id ? id : ":id"}/edit`,
                children:{
                    controllers:{
                        index: "/tasksPlan/statistic/controllers",
                        edit: (id) => `/tasksPlan/statistic/controllers/${id ? id : ":id"}/edit`,
                    },
                    sealers:{
                        index: "/tasksPlan/statistic/sealers",
                        edit: (id) => `/tasksPlan/statistic/sealers/${id ? id : ":id"}/edit`,
                    },
                }
            },
            tasks_primary_filling:{
                index: "/tasksPlan/tasks-primary-filling",
                edit: (id) => `/tasksPlan/tasks-primary-filling/${id ? id : ":id"}/edit`,
                create: "/tasksPlan/tasks-primary-filling/create",
                children: {
                    all: {
                        index: "/tasksPlan/tasks-primary-filling/all",
                    },
                    on_check: {
                        index: "/tasksPlan/tasks-primary-filling/on_check"
                    },
                    done: {
                        index: "/tasksPlan/tasks-primary-filling/done"
                    },
                    declined: {
                        index: "/tasksPlan/tasks-primary-filling/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/tasks-primary-filling/in_work",
                    }
                }
            },
            tasks_for_unsealing:{
                index: "/tasksPlan/tasks-for-unsealing",
                edit: (id) => `/tasksPlan/tasks-for-unsealing/${id ? id : ":id"}/edit`,
                children: {
                    all: {
                        index: "/tasksPlan/tasks-for-unsealing/all"
                    },
                    on_check: {
                        index: "/tasksPlan/tasks-for-unsealing/on_check"
                    },
                    done: {
                        index: "/tasksPlan/tasks-for-unsealing/done"
                    },
                    declined: {
                        index: "/tasksPlan/tasks-for-unsealing/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/tasks-for-unsealing/in_work"
                    }
                }
            },
            filling_tasks:{
                index: "/tasksPlan/filling-tasks",
                edit: (id) => `/tasksPlan/filling-tasks/${id ? id : ":id"}/edit`,
                children: {
                    all: {
                        index: "/tasksPlan/filling-tasks/all"
                    },
                    on_check: {
                        index: "/tasksPlan/filling-tasks/on_check"
                    },
                    done: {
                        index: "/tasksPlan/filling-tasks/done"
                    },
                    declined: {
                        index: "/tasksPlan/filling-tasks/declined"
                    },
                    in_work:{
                        index: "/tasksPlan/filling-tasks/in_work"
                    }
                }
            }
        }
    },
    users: {
        index: "/users",
        children: {
            all: {
                index: "/users/all"
            },
            subscribers: {
                index: "/users/subscribers",
                create: "/users/subscribers/create",
                edit: (id) => `/users/subscribers/${id ? id : ":id"}/edit`,
                edit_bankbooks: (id) => `/users/subscribers/${id ? id : ":id"}/edit/bankbooks`
            },
            operators: {
                index: "/users/operators",
                create: "/users/operators/create",
                edit: (id) => `/users/operators/${id ? id : ":id"}/edit`
            },
            controllers: {
                index: "/users/controllers",
                create: "/users/controllers/create",
                edit: (id) => `/users/controllers/${id ? id : ":id"}/edit`
            },
            lab_administrators: {
                index: "/users/lab-administrators",
                create: "/users/lab-administrators/create",
                edit: (id) => `/users/lab-administrators/${id ? id : ":id"}/edit`
            },
            lab_specialists: {
                index: "/users/lab-specialists",
                create: "/users/lab-specialists/create",
                edit: (id) => `/users/lab-specialists/${id ? id : ":id"}/edit`
            },
            administrators: {
                index: "/users/administrators",
                create: "/users/administrators/create",
                edit: (id) => `/users/administrators/${id ? id : ":id"}/edit`
            },
            super_administrators: {
                index: "/users/super-administrators",
                create: "/users/super-administrators/create",
                edit: (id) => `/users/super-administrators/${id ? id : ":id"}/edit`
            },
            analitic_operators: {
                index: "/users/analitic-operators",
                create: "/users/analitic-operators/create",
                edit: (id) => `/users/analitic-operators/${id ? id : ":id"}/edit`
            },
            call_operators: {
                index: "/users/call-operators",
                create: "/users/call-operators/create",
                edit: (id) => `/users/call-operators/${id ? id : ":id"}/edit`
            },
            sealer_operator: {
                index: "/users/sealer-operators",
                create: "/users/sealer-operators/create",
                edit: (id) => `/users/sealer-operators/${id ? id : ":id"}/edit`
            },
            sealing_specialists: {
                index: "/users/sealing-specialists",
                create: "/users/sealing-specialists/create",
                edit: (id) => `/users/sealing-specialists/${id ? id : ":id"}/edit`
            }
        }
    },
    companies: {
        index: "/companies",
        create: "/companies/create",
        edit: {
            index: (id) => `/companies/${id ? id : ":id"}/edit`,
            information: (id) => `/companies/${id ? id : ":id"}/edit/information`,
            operators: (id) => `/companies/${id ? id : ":id"}/edit/operators`,
            controllers: (id) => `/companies/${id ? id : ":id"}/edit/controllers`,
            sealers: (id) => `/companies/${id ? id : ":id"}/edit/sealers`,
            sealers_operators: (id) => `/companies/${id ? id : ":id"}/edit/sealer-operators`,
            analytics: (id) => `/companies/${id ? id : ":id"}/edit/analytics`,
            sectors: (id) => `/companies/${id ? id : ":id"}/edit/sectors`,
            addresses: (id) => `/companies/${id ? id : ":id"}/edit/addresses`,
            cities: (id) => `/companies/${id ? id : ":id"}/edit/cities`
        }
    },
    laboratories: {
        index: "/laboratories",
        create: "/laboratories/create",
        edit: {
            index: (id) => `/laboratories/${id ? id : ":id"}/edit`,
            information: (id) => `/laboratories/${id ? id : ":id"}/edit/information`,
            services: (id) => `/laboratories/${id ? id : ":id"}/edit/services`,
            administrators: (id) => `/laboratories/${id ? id : ":id"}/edit/administrators`,
            specialists: (id) => `/laboratories/${id ? id : ":id"}/edit/specialists`,
            cities: (id) => `/laboratories/${id ? id : ":id"}/edit/cities`,
        }
    },
    sectors: {
        index: "/sectors",
        create: "/sectors/create",
        edit: {
            index: (id) => `/sectors/${id ? id : ":id"}/edit`,
            information: (id) => `/sectors/${id ? id : ":id"}/edit/information`,
            controllers: (id) => `/sectors/${id ? id : ":id"}/edit/controllers`,
            operators: (id) => `/sectors/${id ? id : ":id"}/edit/operators`,
            streets: (id) => `/sectors/${id ? id : ":id"}/edit/streets`,
            houses: (id) => `/sectors/${id ? id : ":id"}/edit/houses`
        }
    },
    addresses: {
        index: "/addresses",
        children: {
            regions: {
                index: "/addresses/regions",
                create: "/addresses/regions/create",
                edit: {
                    index: (id) => `/addresses/regions/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/regions/${id ? id : ":id"}/edit/information`,
                    cities: (id) => `/addresses/regions/${id ? id : ":id"}/edit/cities`
                }
            },
            cities: {
                index: "/addresses/cities",
                create: "/addresses/cities/create",
                edit: {
                    index: (id) => `/addresses/cities/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/cities/${id ? id : ":id"}/edit/information`,
                    districts: (id) => `/addresses/cities/${id ? id : ":id"}/edit/districts`
                }
            },
            districts: {
                index: "/addresses/districts",
                create: "/addresses/districts/create",
                edit: {
                    index: (id) => `/addresses/districts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/districts/${id ? id : ":id"}/edit/information`,
                    microdistricts: (id) => `/addresses/districts/${id ? id : ":id"}/edit/microdistricts`,
                    streets: (id) => `/addresses/districts/${id ? id : ":id"}/edit/streets`
                }
            },
            microdistricts: {
                index: "/addresses/microdistricts",
                create: "/addresses/microdistricts/create",
                edit: {
                    index: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/information`,
                    streets: (id) => `/addresses/microdistricts/${id ? id : ":id"}/edit/streets`
                }
            },
            streets: {
                index: "/addresses/streets",
                create: "/addresses/streets/create",
                edit: {
                    index: (id) => `/addresses/streets/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/streets/${id ? id : ":id"}/edit/information`,
                    houses: (id) => `/addresses/streets/${id ? id : ":id"}/edit/houses`,
                    sectors: (id) => `/addresses/streets/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/streets/${id ? id : ":id"}/edit/controllers`
                }
            },
            houses: {
                index: "/addresses/houses",
                create: "/addresses/houses/create",
                edit: {
                    index: (id) => `/addresses/houses/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/houses/${id ? id : ":id"}/edit/information`,
                    entrances: (id) => `/addresses/houses/${id ? id : ":id"}/edit/entrances`,
                    apartments: (id) => `/addresses/houses/${id ? id : ":id"}/edit/apartments`,
                    bankbooks: (id) => `/addresses/houses/${id ? id : ":id"}/edit/bankbooks`,
                    sectors: (id) => `/addresses/houses/${id ? id : ":id"}/edit/sectors`,
                    controllers: (id) => `/addresses/houses/${id ? id : ":id"}/edit/controllers`
                }
            },
            entrances: {
                index: "/addresses/entrances",
                create: "/addresses/entrances/create",
                edit: {
                    index: (id) => `/addresses/entrances/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/information`,
                    floors: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/floors`,
                    apartments: (id) => `/addresses/entrances/${id ? id : ":id"}/edit/apartments`
                }
            },
            floors: {
                index: "/addresses/floors",
                create: "/addresses/floors/create",
                edit: {
                    index: (id) => `/addresses/floors/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/floors/${id ? id : ":id"}/edit/information`,
                    apartments: (id) => `/addresses/floors/${id ? id : ":id"}/edit/apartments`
                }
            },
            apartments: {
                index: "/addresses/apartments",
                create: "/addresses/apartments/create",
                edit: {
                    index: (id) => `/addresses/apartments/${id ? id : ":id"}/edit`,
                    information: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/information`,
                    bankbooks: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/bankbooks`,
                    controllers: (id) => `/addresses/apartments/${id ? id : ":id"}/edit/controllers`
                }
            }
        }
    },
    bankbooks: {
        index: "/bankbooks",
        create: "/bankbooks/create",
        edit: {
            index: (id) => `/bankbooks/${id ? id : ":id"}/edit`,
            information: (id) => `/bankbooks/${id ? id : ":id"}/edit/information`,
            devices: (id) => `/bankbooks/${id ? id : ":id"}/edit/devices`,
            documents: (id) => `/bankbooks/${id ? id : ":id"}/edit/documents`,
        }
    },
    devices: {
        index: "/devices",
        create: "/devices/create",
        edit: {
            index: (id) => `/devices/${id ? id : ":id"}/edit`,
            information: (id) => `/devices/${id ? id : ":id"}/edit/information`,
            indications: (id) => `/devices/${id ? id : ":id"}/edit/indications`,
            notifications: (id) => `/devices/${id ? id : ":id"}/edit/notifications`,
            documents: (id) => `/devices/${id ? id : ":id"}/edit/documents`,
            histories: (id) => `/devices/${id ? id : ":id"}/edit/histories`,
        }
    },
    indications: {
        index: "/indications",
        create: "/indications/create",
        edit: {
            index: (id) => `/indications/${id ? id : ":id"}/edit`,
            information: (id) => `/indications/${id ? id : ":id"}/edit/information`,
            comments: (id) => `/indications/${id ? id : ":id"}/edit/comments`,
            history: (id) => `/indications/${id ? id : ":id"}/edit/history`,
        },
        children: {
            all: {
                index: "/indications/all"
            },
            on_check: {
                index: "/indications/on_check"
            },
            approved: {
                index: "/indications/approved"
            },
            declined: {
                index: "/indications/declined"
            }
        }
    },
    dictionaries: {
        index: "/dictionaries",
        children: {
            resource_types: {
                index: "/dictionaries/resource_types",
                create: "/dictionaries/resource_types/create",
                edit: (id) => `/dictionaries/resource_types/${id ? id : ":id"}/edit`
            },
            improvements: {
                index: "/dictionaries/improvements",
                create: "/dictionaries/improvements/create",
                edit: (id) => `/dictionaries/improvements/${id ? id : ":id"}/edit`
            },
            service_types: {
                index: "/dictionaries/service_types",
                create: "/dictionaries/service_types/create",
                edit: (id) => `/dictionaries/service_types/${id ? id : ":id"}/edit`
            },
            application_reject_reason:{
                index: "/dictionaries/application_reject_reasons",
                create: "/dictionaries/application_reject_reasons/create",
                edit: (id) => `/dictionaries/application_reject_reasons/${id ? id : ":id"}/edit`
            },
            sealing_reject_reason:{
                index: "/dictionaries/sealing_reject_reasons",
                create: "/dictionaries/sealing_reject_reasons/create",
                edit: (id) => `/dictionaries/sealing_reject_reasons/${id ? id : ":id"}/edit`
            },
            device_reject_reason:{
                index: "/dictionaries/device_reject_reason",
                create: "/dictionaries/device_reject_reason/create",
                edit: (id) => `/dictionaries/device_reject_reason/${id ? id : ":id"}/edit`
            }
        }
    },
    applications: {
        index: `/applications`,
        create: '/applications/create',
        detail: {
            index: (id) => `/applications/${id ? id : ":id"}`,
            information: (id) => `/applications/${id ? id : ":id"}/information`,
            services: (id) => `/applications/${id ? id : ":id"}/services`,
            specialists: (id) => `/applications/${id ? id : ":id"}/specialists`,
            history: (id) => `/applications/${id ? id : ":id"}/history`
        },
        children: {
            all: {
                index: "/applications/all"
            },
            under_consideration: {
                index: '/applications/under-consideration'
            },
            rejected: {
                index: '/applications/rejected'
            },
            waiting: {
                index: '/applications/waiting'
            },
            cancelled: {
                index: '/applications/cancelled'
            },
            in_work: {
                index: '/applications/in-work'
            },
            done_payment_waiting: {
                index: '/applications/done-payment-waiting'
            },
            done_payment_approve_waiting: {
                index: '/applications/done-payment-approve-waiting'
            },
            done_paid: {
                index: '/applications/done-paid'
            },
        }
    },
    model_сhanges: {
        index: `/modelChanges`,
        edit: {
            index: (id) => `/modelChanges/bankbooks/${id ? id : ":id"}/edit`,
            devices: (id) => `/modelChanges/devices/${id ? id : ":id"}/edit`,
        },
        children:{
            bankbooks:{
                all: {
                    index: "/modelChanges/bankbooks/all"
                },
                on_check: {
                    index: "/modelChanges/bankbooks/on_check"
                },
                approved: {
                    index: "/modelChanges/bankbooks/approved"
                },
                declined: {
                    index: "/modelChanges/bankbooks/declined"
                }
            },
            devices:{
                all: {
                    index: "/modelChanges/devices/all"
                },
                on_check: {
                    index: "/modelChanges/devices/on_check"
                },
                approved: {
                    index: "/modelChanges/devices/approved"
                },
                declined: {
                    index: "/modelChanges/devices/declined"
                }
            },
        }
           
    },
    invoices: {
        index: `/invoices`
    },
    settings: {
        index: '/settings',
        children: {
            indication_settings_app: {
                index: "/settings/indication-settings-app"
            }
        }
    },
    notifications: {
        index: "/notifications",
        create: "/notifications/create",
        detail: (id) => `/notifications/${id ? id : ":id"}`
    },
    data_import: {
        index: "/data-import",
        children: {
            addresses: {
                index: "/data-import/addresses"
            },
            devices: {
                index: "/data-import/devices"
            },
            indications: {
                index: "/data-import/indications"
            },
            devices_to_delete: {
                index:"/data-import/devices-to-delete"
            }
        }
    }
}

export default routePaths;